

























import { ref } from '@vue/composition-api';
import { Component, Vue } from 'vue-property-decorator';
import ViewWrapper from '../components/ViewWrapper.vue';
import Loading from '../components/Loading.vue';
import { getRequest } from '../lib/request';

@Component({
  components: {
    ViewWrapper,
    Loading,
  },
  setup() {
    const loading = ref(true);
    const rooms = ref<any[] | null>(null);

    (async () => {
      rooms.value = await getRequest('rooms');
      loading.value = false;
    })();

    return {
      loading,
      rooms,
    };
  },
})
export default class MultiPlayer extends Vue {}
